import { SubscriptionStatus, } from './types';
import { SUBSCRIPTION_PLANS } from './constants';
import { PLATFORM_BALANCE, REALTIME_SUMMARY_BALANCE, USER_ACTIVITY_MOCK } from './mocks';
const mapSubscriptionPlans = (prices) => {
    const plans = [];
    prices.forEach((price) => {
        if (SUBSCRIPTION_PLANS[price.name]) {
            const key = price.name;
            plans.push((Object.assign(Object.assign({}, SUBSCRIPTION_PLANS[key]), { name: key, id: price.id, currency: price.currency, fee: parseFloat(price.unitAmount) })));
        }
    });
    return plans;
};
const defineCurrentSubscription = (plans) => {
    if (plans.length === 0) {
        return Object.assign(Object.assign({}, SUBSCRIPTION_PLANS.STARTER), { isSelected: true, isInList: false });
    }
    let currentPlan;
    plans.forEach((plan) => {
        if (SUBSCRIPTION_PLANS[plan.platformType] && plan.status === SubscriptionStatus.Active) {
            currentPlan = Object.assign(Object.assign({}, SUBSCRIPTION_PLANS[plan.platformType]), { id: plan.id, name: plan.platformType, isSelected: true, isInList: true });
        }
    });
    return currentPlan ? currentPlan : Object.assign(Object.assign({}, SUBSCRIPTION_PLANS.STARTER), { isSelected: true, isInList: false });
};
const getProfileActivityHistory = () => {
    const activity = [...USER_ACTIVITY_MOCK].sort((a, b) => {
        if (a.timestamp < b.timestamp) {
            return 1;
        }
        return -1;
    });
    const groupedActivity = {};
    activity.forEach((activityEvent) => {
        const eventDate = new Date(activityEvent.timestamp);
        const dateKey = `${eventDate.getFullYear()}-${eventDate.getMonth()}-${eventDate.getDate()}`;
        if (typeof groupedActivity[dateKey] === 'undefined') {
            groupedActivity[dateKey] = {
                date: eventDate,
                positions: [activityEvent],
            };
        }
        else {
            groupedActivity[dateKey].positions.push(activityEvent);
        }
    });
    return groupedActivity;
};
const getPlatformBalance = () => PLATFORM_BALANCE;
const getRealtimeSummaryBalance = () => REALTIME_SUMMARY_BALANCE;
export { mapSubscriptionPlans, defineCurrentSubscription, getProfileActivityHistory, getPlatformBalance, getRealtimeSummaryBalance, };
