const SUBSCRIPTION_PLANS = {
    STARTER: {
        id: 'STARTER_PLAN',
        title: 'Starter',
        description: 'Unlimited free trial: SPOT only',
        isRecommended: false,
        isFree: true,
        isAvailable: true,
        options: {
            limits: 'One portfolio',
            spotCopy: true,
            margin: false,
            futures: false,
            support: 'General support',
        },
        fee: 0,
    },
    ADVANCED: {
        id: 'ADVANCED_PLAN',
        title: 'Advanced',
        description: 'Increase your profits with unlimited semi-manual trading',
        isRecommended: false,
        isAvailable: true,
        options: {
            limits: 'Four portfolio',
            spotCopy: true,
            margin: true,
            futures: false,
            support: 'General support',
        },
        currency: '$',
        fee: 49,
    },
    PROFESSIONAL: {
        id: 'PRO_PLAN',
        title: 'Pro',
        description: 'Increase your profits with unlimited semi-manual trading',
        isAvailable: false,
        options: {
            limits: 'Eight portfolio',
            spotCopy: true,
            margin: true,
            futures: true,
            support: 'Premuim support (24/7)',
        },
        currency: '$',
        fee: 99,
    },
};
export { 
// eslint-disable-next-line import/prefer-default-export
SUBSCRIPTION_PLANS, };
