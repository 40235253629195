import restApiAuthorized from '@store/api/restApiAuthorized';
import { defineCurrentSubscription, mapSubscriptionPlans, getProfileActivityHistory, getPlatformBalance, getRealtimeSummaryBalance, } from './utils';
const userApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            providesTags: ['Me'],
        }),
        getPlatformBalance: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getPlatformBalance(),
        }),
        getRealtimeSummaryBalance: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getRealtimeSummaryBalance(),
        }),
        getProfileActivity: builder.query({
            query: () => ({
                url: '/api/v1/user/me',
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            transformResponse: () => getProfileActivityHistory(),
        }),
        deleteUser: builder.mutation({
            query: () => ({
                url: '/api/v1/user',
                method: 'DELETE',
            }),
        }),
        getSubscriptionPlans: builder.query({
            query: () => ({
                url: '/api/v1/subscriptions_price',
                method: 'GET',
            }),
            transformResponse: mapSubscriptionPlans,
            providesTags: ['AvailableSubscriptions'],
        }),
        getCurrentSubscriptionPlans: builder.query({
            query: ({ userId }) => ({
                url: `/api/v1/user/${userId}/subscription`,
                method: 'GET',
            }),
            transformResponse: defineCurrentSubscription,
            providesTags: ['CurrentSubscriptions'],
        }),
        createSubscription: builder.mutation({
            query: ({ userId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription`,
                method: 'POST',
                body: {
                    price_id: priceId,
                },
            }),
        }),
        createSubscriptionWithCheckout: builder.mutation({
            query: ({ userId, priceId }) => ({
                url: `/api/v1/user/${userId}/subscription_checkout`,
                method: 'POST',
                body: {
                    priceId,
                    type: 'PORTAL',
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),
        disableSubscription: builder.mutation({
            query: ({ userId, subscriptionId }) => ({
                url: `/api/v1/user/${userId}/subscription/${subscriptionId}/disable`,
                method: 'PATCH',
            }),
            invalidatesTags: ['CurrentSubscriptions', 'AvailableSubscriptions'],
        }),
    }),
});
export default userApi;
export const { useGetMeQuery, useLazyGetMeQuery, useGetPlatformBalanceQuery, useGetRealtimeSummaryBalanceQuery, useGetProfileActivityQuery, useLazyGetProfileActivityQuery, useDeleteUserMutation, useGetSubscriptionPlansQuery, useGetCurrentSubscriptionPlansQuery, useLazyGetSubscriptionPlansQuery, useCreateSubscriptionMutation, useCreateSubscriptionWithCheckoutMutation, useDisableSubscriptionMutation, } = userApi;
